import Cookie from "js-cookie";

const cookie = () => {
  const dev = process.env.NODE_ENV === "development";

  /**
   * use to set cookie
   * @param {*} key
   * @param {*} value
   */
  const setCookie = (key, value) => {
    if (dev) {
      return window.localStorage.setItem(key, value);
    } else {
      return Cookie.set(key, value, {
        path: "/",
        expires: 60,
        domain: process.env.REACT_APP_PARENT_DOMAIN,
      });
    }
  };

  /**
   * use to get cookie
   * @param {*} key
   */
  const getCookie = (key) => {
    if (dev) {
      return window.localStorage.getItem(key);
    } else {
      console.log(Cookie.get("token"))
      return Cookie.get(key);
    }
  };

  /**
   * use to delete cookie
   * @param {*} key
   */
  const deleteCookie = (key) => {
    if (dev) {
      return window.localStorage.removeItem(key);
    } else {
      return Cookie.remove(key, {
        path: "/",
        domain: process.env.REACT_APP_PARENT_DOMAIN,
      });
    }
  };

  return { setCookie, getCookie, deleteCookie };
};

export default cookie;
