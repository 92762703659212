import React, { useState, useEffect } from "react";
import {  useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateLink } from "../../services";
import Invalid from "../../components/Invalid/Invalid";
import styles from "./Track.module.scss";

//components;
import Navbar from "../../components/Navbar/Navbar";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import Verify from "../../components/Verify/Verify";
import PaymentDetails from "../../components/PaymentDetails/PaymentDetails";
import Confirmation from "../../components/Confirmation/Confirmation";

const Track = ({  }) => {
  const [currentStep, setCurrentStep] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userHistory, setUserHistory] = useState(["payment_details"]);
  const { transactionId } = useParams();

  useEffect(() => {
    if (!transactionId) {
      setCurrentStep("invalid");
      setUserHistory([...userHistory, "invalid"]);
      setShowModal(true);
      console.log("invalid transaction");
    } else {
      setCurrentStep("payment_details");
      setShowModal(true);
    }
  }, [transactionId]);

  const { data: transaction, isLoading: isGettingTransaction } = useQuery(
    ["validate Link"],
    () => validateLink(transactionId ? transactionId : ""),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.log({ error });
        setCurrentStep("invalid");
        setShowModal(true);
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  return (
    <>
      <div className={styles.track}>
        <Navbar />
      </div>

      {showModal && (
        <ModalWrapper
          userHistory={userHistory}
          setUserHistory={setUserHistory}
          showModal={showModal}
          setShowModal={setShowModal}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        >
          {currentStep === "verify" && (
            <Verify setCurrentStep={setCurrentStep} />
          )}
          {currentStep === "confirmation" && (
            <Confirmation setCurrentStep={setCurrentStep} />
          )}
          {currentStep === "payment_details" && (
            <PaymentDetails
              data={transaction}
              isLoading={isGettingTransaction}
            />
          )}
          {currentStep === "invalid" && <Invalid />}
        </ModalWrapper>
      )}
    </>
  );
};

export default Track;
