import React from "react";
import styles from "./Failed.module.scss";

//images
import FailedIcon from "../../assets/icons/failed.gif";
import WhatsappLogo from "../../assets/icons/whatsapp-logo.png";
import TelegramLogo from "../../assets/icons/icons8-telegram-app-48.png";

const Failed = ({ channel }) => {
  return (
    <div className={styles.failed}>
      <img src={FailedIcon} className={styles.failed__icon} />
      <h3>Payment Failed</h3>
      <p className={styles.invalid__message}>
        We weren’t able to process your payment. Please try again.
      </p>

      <button
        onClick={() => {
          if (channel === "TELEGRAM") {
            // window.location.href = "https://t.me/BlaaizBot";
            window.location.href = window.location.hostname.includes("dev.") ? "https://t.me/BlaizRemittanceBot" : "https://t.me/BlaaizBot";
          } else {
            window.location.href = "https://wa.me/+14155238886";
          }
        }}
      >
        Try again
      </button>

      {/* <div className={styles.failed__buttons}>
        <button
          onClick={() => {
            if (channel === "TELEGRAM") {
              window.location.href = "https://t.me/BlaaizBot";
            } else {
              window.location.href = "https://wa.me/+14155238886";
            }
          }}
        >
          Try again
        </button>
        <button
          onClick={() => {
            if (channel === "TELEGRAM") {
              window.location.href = "https://t.me/BlaaizBot";
            } else {
              window.location.href = "https://wa.me/+14155238886";
            }
          }}
        >
          {channel === "TELEGRAM" ? (
            <img
              src={TelegramLogo}
              className={styles.failed__telegram}
              alt="telegram logo"
            />
          ) : (
            <img src={WhatsappLogo} alt="whatsapp logo" />
          )}
        </button> 
      </div>*/}
    </div>
  );
};

export default Failed;
