import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  cardPayment,
  getVirtualAccount,
  validateLink,
  generateLink,
  getLink,
} from "../../services";
import useChannel from "../../Hooks/useChannel";
import Invalid from "../../components/Invalid/Invalid";
import styles from "./Home.module.scss";

//components;
import Navbar from "../../components/Navbar/Navbar";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import PaymentOption from "../../components/PaymentOption/PaymentOption";
import CardDetails from "../../components/CardDetails/CardDetails";
import Transfer from "../../components/Transfer/Transfer";
import PaymentCrypto from "../../components/PaymentCrypto/PaymentCrypto";
import ConfirmTransfer from "../../components/ConfirmTransfer/ConfirmTransfer";
import PaymentDetails from "../../components/PaymentDetails/PaymentDetails";
import Success from "../../components/Success/Success";
import Failed from "../../components/Failed/Failed";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const Home = ({ formData, setFormData }) => {
  const [currentStep, setCurrentStep] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [amount, setAmount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [transferDetails, setTransferDetails] = useState("");
  const [userHistory, setUserHistory] = useState(["payment_options"]);
  const [callGetLink, setCallGetLink] = useState(false);
  const [callGetTransaction, setCallGetTransaction] = useState(true);
  const { transactionId } = useParams();

  useEffect(() => {
    if (!transactionId) {
      setCurrentStep("invalid");
      setUserHistory([...userHistory, "invalid"]);
      setShowModal(true);
      console.log("invalid transaction");
    } else {
      setCurrentStep("payment_options");
      //setCurrentStep("success");
      setShowModal(true);
    }
  }, [transactionId]);

  const resetAmountField = () => {
    setInputValue("");
    setAmount(0);
  };

  const { data: transaction, isLoading: isGettingTransaction } = useQuery(
    ["validate Link"],
    () => validateLink(transactionId ? transactionId : ""),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: callGetTransaction,
      onSuccess: (data) => {
        console.log(data, "transactionDetails details");
        setCallGetTransaction(false);
        document.title = `Send ${data?.amount ?? ""} ${
          data?.currency ?? ""
        } home`;
        if (data?.status === "SUCCESSFUL") {
          setCurrentStep("success");
          setShowModal(true);
        }
        if (data?.status === "FAILED") {
          setCurrentStep("failed");
          setShowModal(true);
        }
        if (!data?.status) {
          setCurrentStep("invalid");
          setShowModal(true);
        }
        // setShowModal(true);
      },
      onError: (error) => {
        console.log({ error });
        setCurrentStep("invalid");
        setCallGetTransaction(false);
        setShowModal(true);
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const {
    data: generatedLink,
    isLoading: isGeneratingLink,
    mutate: _generateLink,
  } = useMutation(
    ["generate link"],
    () =>
      generateLink(transactionId ?? "", {
        local_amount: transaction?.amount,
        local_currency: transaction?.currency,
      }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        console.log(data, "generate Link result");
        window.location?.assign(`https://link.useknit.io/${data?.reference}`);
      },
      onError: (error) => {
        console.log({ error });
        // setCurrentStep("invalid");
        setShowModal(true);
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const { data: link, isLoading: isGettingLink } = useQuery(
    ["get link"],
    () => getLink(transactionId ?? "", generatedLink?.reference ?? ""),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: callGetLink,
      onSuccess: (data) => {
        console.log(data, "get link result");
      },
      onError: (error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const {
    isLoading,
    data,
    mutate: _cardPayment,
  } = useMutation(
    ["card payment"],
    (payload) => cardPayment(transactionId, payload),
    {
      onSuccess: (data) => {
        console.log(data, "card payment");
        setShowModal(false);
        //setShowRedirect(true)
        //setCallGetTransaction(true);
        window.location.href = data?.redirectUrl;
        //setCurrentStep("");
      },
      onError: (error) => {
        setError(error?.response?.data?.message);
        setCallGetTransaction(true);
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const {
    isLoading: isLoadingVirtual,
    mutate: _virtualAccount,
    data: accountDetails,
  } = useMutation(
    ["virtual account"],
    (payload) => getVirtualAccount(payload),
    {
      onSuccess: (data) => {
        console.log(data);
        setCurrentStep("transfer_confirmation");
      },
      onError: (error) => {
        setError(error?.response?.data?.message);
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  // useEffect(() => {
  //   if (generatedLink?.reference) {
  //     setCallGetLink(true);
  //   }
  // }, [generatedLink]);

  return (
    <>
      <div className={styles.home}>
        <Navbar />
      </div>

      {showModal && (
        <ModalWrapper
          userHistory={userHistory}
          setUserHistory={setUserHistory}
          showModal={showModal}
          setShowModal={setShowModal}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        >
          {currentStep === "payment_options" && (
            <PaymentOption
              setCurrentStep={setCurrentStep}
              isGettingTransaction={isGettingTransaction}
              setUserHistory={setUserHistory}
              userHistory={userHistory}
              transaction={transaction}
            />
          )}

          {currentStep === "debit_credit_card_option" && (
            <CardDetails
              setCurrentStep={setCurrentStep}
              setFormData={setFormData}
              formData={formData}
              error={error}
              setError={setError}
              _cardPayment={_cardPayment}
              isLoading={isLoading}
              amount={transaction?.amount}
              currency={transaction?.currency}
              setCallGetTransaction={setCallGetTransaction}
            />
          )}

          {currentStep === "transfer_option" && (
            <Transfer
              setCurrentStep={setCurrentStep}
              setTransferDetails={setTransferDetails}
              _virtualAccount={_virtualAccount}
              isLoading={isLoadingVirtual}
              amount={amount}
              currency={transaction?.currency}
            />
          )}

          {currentStep === "payment_details" && <PaymentDetails />}

          {currentStep === "crypto_option" && (
            <PaymentCrypto
              _coinOptions={link?.networks}
              transactionData={link?.link}
              userData={link?.user}
              generatedLink={generatedLink}
              isGeneratingLink={isGeneratingLink}
              _generateLink={_generateLink}
              isGettingLink={isGettingLink}
              transactionId={transactionId}
            />
          )}

          {currentStep === "transfer_confirmation" && (
            <ConfirmTransfer
              accountDetails={accountDetails}
              transferDetails={transferDetails}
              setShowModal={setShowModal}
              resetAmountField={resetAmountField}
              currency={transaction?.currency}
            />
          )}
          {currentStep === "invalid" && <Invalid />}
          {currentStep === "coming-soon" && <ComingSoon />}
          {currentStep === "success" && (
            <Success
              channel={transaction?.channel}
              recipient={transaction?.recipient}
            />
          )}
          {currentStep === "failed" && (
            <Failed channel={transaction?.channel} />
          )}
        </ModalWrapper>
      )}
    </>
  );
};

export default Home;
