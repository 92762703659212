import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPurchasedGiftCode } from "../../services";
import styles from "./Redirect.module.scss";

//components;
import Navbar from "../../components/Navbar/Navbar";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";

import Success from "../../components/Success/Success";
import Failed from "../../components/Failed/Failed";

const Redirect = ({ formData }) => {
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState();
  //const [reference , setReference] = useState()
  const { reference, email } = useParams();
  const navigate = useNavigate();

  const { isLoading, data , mutate: _getGiftCode } = useQuery(
    ["get gift code"],
    () => getPurchasedGiftCode(reference, email),
    {
      onSuccess: (data) => {
        console.log(data, "second redirect data");
        if (data?.code === "00") {
          setShowModal(true);
          setStatus("success");
          return;
        }
        setShowModal(true);
        setStatus("failed");
      },
      onError: (error) => {
        console.log({ error });
        setShowModal(true);
        setStatus("failed");
        toast.error(error?.response?.data?.message ?? "an error occured", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  useEffect(() => {
    console.log(reference, "reference");
    if (!reference || !email) {
      // setShowModal(true)
      // setStatus("failed");
      navigate("/")
    }
  }, [reference, email]);


  useEffect(() => {
    if(!showModal && !isLoading){
      navigate("/")
    }
  }, [showModal , isLoading])
  

  return (
    <>
      <div className={styles.redirect}>
        <Navbar />

        <div>
          {isLoading ? (
            <p>
              Please wait
              <ReactLoading
                type="bubbles"
                width={30}
                height={30}
                color="#fff"
              />
            </p>
          ) : null}
        </div>
      </div>

      {showModal && status === "success" ? (
        <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
          <Success giftcode={data?.giftcode} />
        </ModalWrapper>
      ) : null}

      {showModal && status === "failed" ? (
        <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
          <Failed />
        </ModalWrapper>
      ) : null}
    </>
  );
};

export default Redirect;
