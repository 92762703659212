import React, { useState } from "react";
import { useQuery } from "react-query";
import { Copy, WarningCircle } from "phosphor-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import Success from "../Success/Success";
import Failed from "../Failed/Failed";

import { madePayment, getTransaction } from "../../services";
import styles from "./ConfirmTransfer.module.scss";

const ConfirmTransfer = ({
  accountDetails,
  transferDetails,
  resetAmountField,
  setShowModal,
  currency,
}) => {
  const [status, setStatus] = useState("initial");
  const [enabled, setEnabled] = useState(false);
  const [getTransactionEnabled, setGetTransactionEnabled] = useState(false);

  const handleCopy = (accountNumber) => {
    navigator.clipboard.writeText(accountNumber);

    toast.success("Account number copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const { isLoading: isConfirmingTransfer } = useQuery(
    ["confirm transfer"],
    () => madePayment(accountDetails?.external_transaction_id),
    {
      enabled: enabled,
      refetchInterval: getTransactionEnabled ? false : 5000,
      onSuccess: (data) => {
        console.log(data?.data?.transaction?.status, "status");
        if (data?.data?.transaction?.status === "SUCCESSFUL") {
          console.log("called");

          setGetTransactionEnabled(true);
        }
        console.log(data);
      },
      onError: (error) => {
        setStatus("failed");
        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  const { isLoading: isGettingTransaction, data: transactionData } = useQuery(
    ["get Transaction"],
    () =>
      getTransaction(
        accountDetails?.external_transaction_id,
        transferDetails?.email
      ),
    {
      enabled: getTransactionEnabled,
      onSuccess: (data) => {
        setStatus("success");

        console.log(data);
      },
      onError: (error) => {
        setStatus("failed");

        toast.error(error?.response?.data?.message ?? "an error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  return (
    <>
      {status === "initial" && (
        <div className={styles.confirmTransfer}>
          <h2 className={styles.confirmTransfer__heading}>Confirm Transfer</h2>

          <div className={styles.confirmTransfer__form}>
            <div className={styles.confirmTransfer__form__account}>
              <div className={styles.confirmTransfer__form__account__details}>
                <p>
                  {accountDetails?.account_number}
                  <span
                    onClick={() => handleCopy(accountDetails?.account_number)}
                    className={styles.confirmTransfer__form__account__copy}
                  >
                    <Copy size={22} color="#a2a2a2" />
                  </span>
                </p>
                <p>{accountDetails?.bank_name}</p>
                <p>{accountDetails?.account_name}</p>
                <p>-</p>
                <p>
                  Amount = {currency}
                  {accountDetails?.transaction_amount_payable}
                </p>
              </div>
            </div>

            <p className={styles.confirmTransfer__form__notice}>
              <WarningCircle size={18} color="rgba(255, 165, 0, 1)" />
              Purchased Blaizs can only be redeemed by the purchaser
            </p>

            <div className={styles.confirmTransfer__form__item}>
              <button
                onClick={() => {
                  setEnabled(true);
                }}
              >
                {enabled || getTransactionEnabled ? (
                  <p>
                    <span>Confirming Payment</span>
                    {"  "}
                    <ReactLoading
                      type="spin"
                      width={25}
                      height={25}
                      color="#fff"
                    />
                  </p>
                ) : (
                  `I have transferred ${currency}${accountDetails?.transaction_amount_payable}`
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {status === "success" && (
        <Success
          giftcode={transactionData?.giftcode}
          setShowModal={setShowModal}
          resetAmountField={resetAmountField}
        />
      )}
      {status === "failed" && (
        <Failed
          setShowModal={setShowModal}
          resetAmountField={resetAmountField}
        />
      )}
    </>
  );
};

export default ConfirmTransfer;
