import React, { useState } from "react";
import CreditCardInput from "react-credit-card-input";
import ReactLoading from "react-loading";
import Success from "../Success/Success";
import Failed from "../Failed/Failed";

import styles from "./CardDetails.module.scss";

const CardDetails = ({
  formData,
  setFormData,
  _cardPayment,
  error,
  setError,
  isLoading,
  amount,
  currency,
}) => {
  const [status, setStatus] = useState("initial");

  const [cardInfoError, setCardInfoError] = useState();

  const handleCardDetailsForm = (formData) => {
    const emptyItem = Object.keys(formData).find((key) => !formData[key]);

    if (emptyItem) {
      setError(`${emptyItem} is required`);
      return;
    }
    if (cardInfoError) {
      setError(cardInfoError);
      return;
    }
    setError("");
    _cardPayment({
      //   ...formData,
      // email: formData.card_holder_email,
      account_name: formData.card_holder_name,
      amount: Number(amount),
      card_number: formData?.card_number?.replaceAll(" ", ""),
      cvc: formData?.cvc,
      expiry: formData?.expiry?.replaceAll(" ", ""),
    });
  };
  return (
    <>
      {status === "initial" && (
        <div className={styles.cardDetails}>
          <h2 className={styles.cardDetails__heading}>
            Payment via debit/credit card{" "}
          </h2>
          <p className={styles.cardDetails__desc}>All transactions are secure and encrypted.</p>

          <div className={styles.cardDetails__form}>
            <div className={styles.cardDetails__form__item}>
              <label htmlFor="card_holder_name">Cardholder name</label>
              <input
                className={styles.cardDetails__form__item__input}
                type="text"
                name="card_holder_name"
                id="card_holder_name"
                value={formData?.card_holder_name}
                onChange={(e) =>
                  setFormData({ ...formData, card_holder_name: e.target.value })
                }
              />
            </div>
            <div className={styles.cardDetails__form__item}>
              <label htmlFor="card_holder_name">Card number</label>
              <CreditCardInput
                onError={({ inputName, err }) => setCardInfoError(err)}
                cardNumberInputProps={{
                  value: formData?.card_number,
                  onChange: (e) =>
                    setFormData({ ...formData, card_number: e.target.value }),
                }}
                cardExpiryInputProps={{
                  value: formData?.expiry,
                  onChange: (e) =>
                    setFormData({ ...formData, expiry: e.target.value }),
                }}
                cardCVCInputProps={{
                  value: formData?.cvc,
                  onChange: (e) =>
                    setFormData({ ...formData, cvc: e.target.value }),
                }}
                fieldClassName={styles.cardDetails__form__item__card}
                containerClassName={
                  styles.cardDetails__form__item__card__container
                }
              />
            </div>
            {/* <div className={styles.cardDetails__form__item}>
              <label htmlFor="card_holder_email">Card Holder Email</label>
              <input
                type="email"
                className={styles.cardDetails__form__item__input}
                name="card_holder_email"
                id="card_holder_email"
                value={formData?.card_holder_email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    card_holder_email: e.target.value,
                  })
                }
              />
            </div> */}
            <p className={styles.cardDetails__form__error}>
              {error && typeof error === "string"
                ? error.replaceAll("_", " ")
                : ""}
            </p>
            <div className={styles.cardDetails__form__item}>
              {/*  () => setStatus("success") */}
              {/* € */}
              <button onClick={() => handleCardDetailsForm(formData)}>
                {isLoading ? (
                  <ReactLoading
                    type="spin"
                    width={30}
                    height={30}
                    color="#fff"
                  />
                ) : (
                  // `Pay ${Number(amount)} ${currency}`
                  `Continue`
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      {status === "failed" && <Failed />}
      {status == "success" && <Success setStatus={setStatus} />}
    </>
  );
};

export default CardDetails;
