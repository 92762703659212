import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import HouseIcon from "../../assets/icons/house.png";
import capitalize from "../../helpers/capitalize";

import styles from "./PaymentDetails.module.scss";
import { HourglassSimpleMedium, XCircle, CheckCircle } from "phosphor-react";

const PaymentDetails = ({ data, isLoading }) => {
  const [activeTab, setActiveTab] = useState("status");

  // const transaction = {
  //   status: "SUCCESSFUL",
  // };

  useEffect(() => {
    console.log(dateFormat(data?.created_at, "mmm d"));
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div className={styles.paymentDetails__loading}>
          <div className={styles.paymentDetails__loading__content}>
            {/* <p>Please wait</p> */}
            <ReactLoading
              type="spinningBubbles"
              color="#222222"
              width={30}
              height={30}
            />
          </div>
        </div>
      ) : (
        <div className={styles.paymentDetails}>
          <h2 className={styles.paymentDetails__heading}>
            Transaction Details
          </h2>
          <p className={styles.paymentDetails__desc}>
            Your transfer of {data?.sender_local_amount}{" "}
            {data?.sender_local_currency} to {data?.recipient?.fullname} was
            successful.
          </p>
          {/* <div className={styles.paymentDetails__icon}>
            <img src={HouseIcon} />
          </div> */}
          {/* <div className={styles.paymentDetails__desc}>
            <p>
              <span>
                Sent{" "}
                <b>
                  {data?.amount} {data?.currency}
                </b>{" "}
                <br />
              </span>
              <span>
                to <b>{data?.recipient?.fullname}</b>
              </span>
            </p>
          </div> */}

          {/* <div className={styles.paymentDetails__status}>
            {data?.status === "PENDING" && (
              <HourglassSimpleMedium size={25} color="#0F295C" />
            )}
            {data?.status === "FAILED" && (
              <XCircle weight="fill" color="#E10000" size={25} />
            )}
            {data?.status === "SUCCESSFUL" && (
              <CheckCircle size={25} color="#39A648" />
            )}
            <h4
              className={styles.paymentDetails__status}
              style={
                data?.status === "SUCCESSFUL"
                  ? { color: "#39A648" }
                  : data?.status === "FAILED"
                  ? { color: "#E10000" }
                  : { color: "#0F295C" }
              }
            >
              {capitalize(data?.status ?? "")}
            </h4>
          </div> */}

          <div className={styles.paymentDetails__info}>
            <div className={styles.paymentDetails__info__tabs}>
              <div
                onClick={() => setActiveTab("status")}
                className={
                  activeTab === "status"
                    ? `${styles.paymentDetails__info__tab} ${styles.paymentDetails__info__tab__active}`
                    : `${styles.paymentDetails__info__tab}`
                }
              >
                <p>Status</p>
              </div>
              <div
                onClick={() => setActiveTab("details")}
                className={
                  activeTab === "details"
                    ? `${styles.paymentDetails__info__tab} ${styles.paymentDetails__info__tab__active}`
                    : `${styles.paymentDetails__info__tab}`
                }
              >
                <p>Details</p>
              </div>
            </div>

            {activeTab === "status" && (
              <div className={styles.paymentDetails__info__status}>
                <div className={styles.paymentDetails__info__status__item}>
                  {/* <h4>{dateFormat(data?.created_at, "mmm d")}</h4> */}
                  <div
                    className={styles.paymentDetails__info__status__item__link}
                  >
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__head
                      }
                    ></div>
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__body
                      }
                    ></div>
                  </div>
                  <p>Transfer created</p>
                </div>
                {/* {data?.recipient?.payout_status === "PROCESSING" ||
                data?.recipient?.payout_status === "SUCCESSFUL" ? ( */}
                <div
                  className={
                    data?.recipient?.payout_status === "PROCESSING" ||
                    data?.recipient?.payout_status === "SUCCESSFUL"
                      ? `${styles.paymentDetails__info__status__item}`
                      : `${styles.paymentDetails__info__status__item}  ${styles.paymentDetails__info__status__item__disabled}`
                  }
                >
                  {/* <h4>{dateFormat(data?.created_at, "mmm d")}</h4> */}
                  <div
                    className={styles.paymentDetails__info__status__item__link}
                  >
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__head
                      }
                    ></div>
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__body
                      }
                    ></div>
                  </div>
                  <p>We processed the transfer</p>
                </div>
                {/* ) : null} */}
                {/* {data?.recipient?.payout_status === "SUCCESSFUL" ||
                data?.recipient?.payout_status === "PROCESSING" ? ( */}
                <div
                  className={
                    data?.recipient?.payout_status === "SUCCESSFUL"
                      ? `${styles.paymentDetails__info__status__item}`
                      : `${styles.paymentDetails__info__status__item} ${styles.paymentDetails__info__status__item__disabled}`
                  }
                >
                  {/* <h4>{dateFormat(data?.created_at, "mmm d")}</h4> */}
                  <div
                    className={styles.paymentDetails__info__status__item__link}
                  >
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__head
                      }
                    ></div>
                    <div
                      className={
                        styles.paymentDetails__info__status__item__link__body
                      }
                    ></div>
                  </div>
                  <div>
                    <p>Transfer successful</p>{" "}
                    <h5>
                      It may take a few minutes for the funds to reflect in{" "}
                      {data?.recipient?.fullname ?? "- -"}'s account
                    </h5>
                  </div>
                </div>
                {/* ) : null} */}
              </div>
            )}

            {activeTab === "details" && (
              <div className={styles.paymentDetails__info__details}>
                <div className={styles.paymentDetails__info__detail}>
                  <div className={styles.paymentDetails__info__detail__content}>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Transaction reference</h5>
                      <p>{data?.reference}</p>
                      {/* <p>{data?.external_transaction_reference}</p> */}
                    </div>
                  </div>
                </div>

                <div className={styles.paymentDetails__info__detail}>
                  {/* <h4 className={styles.paymentDetails__info__detail__heading}>
                  RECEIVER DETAILS{" "}
                </h4> */}

                  <div className={styles.paymentDetails__info__detail__content}>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Money sent</h5>
                      <p>
                        {data?.sender_local_amount ?? ""}{" "}
                        {data?.sender_local_currency ?? ""}
                      </p>
                    </div>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Transfer fees</h5>
                      <p> 0.00 {data?.sender_local_currency ?? ""}</p>
                    </div>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Exchange rate</h5>
                      <p>
                        {" "}
                        1 {data?.sender_local_currency} ={" "}
                        {Number(
                          data?.recipient?.amount / data?.sender_local_amount
                        )?.toFixed(3) ?? ""}{" "}
                        {data?.recipient?.local_currency ?? ""}{" "}
                      </p>
                    </div>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Receive amount</h5>
                      <p>
                        {data?.recipient?.amount ?? ""}{" "}
                        {data?.recipient?.local_currency ?? ""}
                      </p>
                    </div>
                  </div>
                </div>

                <div className={styles.paymentDetails__info__detail}>
                  <h4 className={styles.paymentDetails__info__detail__heading}>
                    RECEIVER DETAILS{" "}
                  </h4>

                  <div className={styles.paymentDetails__info__detail__content}>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Receiver’s name</h5>
                      <p>{data?.recipient?.fullname ?? "- -"}</p>
                    </div>

                    {/* <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Mobile number</h5>
                      <p>{data?.recipient?.phone_number ?? "- -"}</p>
                    </div> */}
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Bank</h5>
                      <p>{data?.recipient?.bank_name ?? "- -"}</p>
                    </div>
                    <div
                      className={
                        styles.paymentDetails__info__detail__content__item
                      }
                    >
                      <h5>Account Number</h5>
                      <p>{data?.recipient?.account_number ?? "- -"}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentDetails;
