import React, { useState } from "react";
import ReactLoading from "react-loading";
import styles from "./PaymentOption.module.scss";

//logos
// import BTCLogo from "../../assets/icons/bitcoin.png";
// import EthLogo from "../../assets/icons/ethereum.png";
// import USDTLogo from "../../assets/icons/usdt-bsc.jpg";
// import MasterCardLogo from "../../assets/icons/icons8-mastercard-logo-48.png";
// import VisaLogo from "../../assets/icons/icons8-visa-48.png";
// import HSBCLogo from "../../assets/icons/icons8-hsbc-48.png";
// import MonzoLogo from "../../assets/icons/icons8-monzo-48.png";

import Ethereum from "../../assets/icons/ethereum-new.png";
import Card from "../../assets/icons/debit-card-left.png";
import Transfer from "../../assets/icons/bank-2.png";

const PaymentOption = ({
  setCurrentStep,
  setUserHistory,
  userHistory,
  isGettingTransaction,
  transaction,
}) => {
  const [selected, setSelected] = useState("");

  const handleSubmit = () => {
    if (!selected) {
      return;
    }
    if (selected === "transfer") {
      setUserHistory([...userHistory, "coming-soon"]);
      setCurrentStep("coming-soon");
      return;
    }
    setUserHistory([...userHistory, selected]);
    setCurrentStep(selected);
  };
  return (
    <>
      {isGettingTransaction ? (
        <div className={styles.options__loading}>
          <div className={styles.options__loading__content}>
            {/* <p>Please wait</p> */}
            <ReactLoading
              type="spinningBubbles"
              color="#222222"
              width={30}
              height={30}
            />
          </div>
        </div>
      ) : (
        <div className={styles.options}>
          {/* <h2>
            You're sending {transaction?.amount} {transaction?.currency} to{" "}
            {transaction?.recipient?.fullname} <br />
            They will receive {transaction?.recipient?.amount}{" "}
            {transaction?.recipient?.local_currency}
          </h2> */}
          <h3 className={styles.options__heading}>Payment option</h3>
          <p className={styles.options__desc}>
            Select your preferred choice of payment below.
          </p>

          <div className={styles.options__items}>
            <div
              onClick={() => {
                setSelected("debit_credit_card_option");
              }}
              className={styles.options__item}
            >
              <div className={styles.options__item__img}>
                <img src={Card} alt="card" />
              </div>
              <div className={styles.options__item__info}>
                <h3>Debit/Credit card</h3>
                <p>Recipient will receive payment within minutes.</p>
              </div>

              <div
                className={
                  selected === "debit_credit_card_option"
                    ? `${styles.options__item__ring} ${styles.options__item__ring__active}`
                    : `${styles.options__item__ring}`
                }
              ></div>
            </div>
            <div
              onClick={() => {
                setSelected("crypto_option");
              }}
              className={styles.options__item}
            >
              <div className={styles.options__item__img}>
                <img src={Ethereum} alt="ethereum" />
              </div>
              <div className={styles.options__item__info}>
                <h3>Crypto payments</h3>
                <p>Recipient will receive payment within minutes.</p>
              </div>

              <div
                className={
                  selected === "crypto_option"
                    ? `${styles.options__item__ring} ${styles.options__item__ring__active}`
                    : `${styles.options__item__ring}`
                }
              ></div>
            </div>
            <div
              onClick={() => {
                setSelected("transfer");
              }}
              className={`${styles.options__item} 
       
              `}
            >
              <div className={styles.options__item__img}>
                <img src={Transfer} alt="ethereum" />
              </div>
              <div className={styles.options__item__info}>
                <h3>Bank Transfer</h3>
                <p>Recipient will receive payment within 24 - 48 hours. </p>
              </div>
              <div
                className={
                  selected === "transfer"
                    ? `${styles.options__item__ring} ${styles.options__item__ring__active}`
                    : `${styles.options__item__ring}`
                }
              ></div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className={
              selected
                ? `${styles.options__submit}`
                : `${styles.options__submit} ${styles.options__submit__disabled}`
            }
          >
            Continue
          </button>
        </div>
      )}
    </>
  );
};

export default PaymentOption;
