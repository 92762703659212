import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Success.module.scss";

//images
import CheckCircle from "../../assets/icons/success.gif";
import WhatsappLogo from "../../assets/icons/whatsapp-logo.png";
import TelegramLogo from "../../assets/icons/icons8-telegram-app-48.png";

const Success = ({ channel, recipient }) => {
  return (
    <div className={styles.success}>
      <img src={CheckCircle} className={styles.success__icon} />
      <h3>Payment Successful</h3>
      <p className={styles.invalid__message}>
        Your payment of {recipient?.amount?.toLocaleString()}{" "}
        {recipient?.local_currency} has successfully been sent to{" "}
        {recipient?.fullname}
      </p>

      <button
        onClick={() => {
          if (channel === "TELEGRAM") {
            // window.location.href = "https://t.me/BlaaizBot";
            window.location.href = window.location.hostname.includes("dev.")
              ? "https://t.me/BlaizRemittanceBot"
              : "https://t.me/BlaaizBot";
          } else {
            window.location.href =
              "https://wa.me/+14155238886?text=I%20have%20made%20Payment";
          }
        }}
      >
        Complete
      </button>

      {/* <button
        onClick={() => {
          if (channel === "TELEGRAM") {
            window.location.href = "https://t.me/BlaaizBot";
          } else {
            window.location.href =
              "https://wa.me/+14155238886?text=I%20have%20made%20Payment";
          }
        }}
      >
        {channel === "TELEGRAM" ? (
          <img
            src={TelegramLogo}
            className={styles.success__telegram}
            alt="telegram logo"
          />
        ) : (
          <img src={WhatsappLogo} alt="whatsapp logo" />
        )}
        Back to Blaiz
      </button> */}
    </div>
  );
};

export default Success;
