import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

import styles from "./Confirmation.module.scss";
import { WarningCircle } from "phosphor-react";
import InputPin from "../InputPin/InputPin";

const Confirmation = ({ setCurrentStep }) => {
  const [pin, setPin] = useState("");
  useEffect(() => {
    console.log(pin);
  }, [pin]);

  const handleConfirmationForm = (confirmationFormData) => {
    const emptyItem = Object.keys(confirmationFormData).find(
      (key) => !confirmationFormData[key]
    );

    if (emptyItem) {
      toast.error(`${emptyItem?.replaceAll("_", " ")} is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // setConfirmationDetails(confirmationFormData);

    // _virtualAccount({
    //   ...confirmationFormData,
    //   amount: Number(amount) / 100,
    // });
  };
  return (
    <>
      <div className={styles.confirmation}>
        <h2 className={styles.confirmation__heading}>Verify Account</h2>
        <p className={styles.confirmation__desc}>
          Input the 5-digits code that was sent to +2348077665544 on
          Whatsapp/SMS
        </p>
        <div className={styles.confirmation__form}>
          <div className={styles.confirmation__form__item}>
            <InputPin value={pin} setValue={setPin} />
          </div>

          {/* <p className={styles.confirmation__form__notice}>
            <WarningCircle size={18} color="rgba(255, 165, 0, 1)" />
            Purchased Blaizs can only be redeemed by the purchaser
          </p> */}

          <div className={styles.confirmation__form__item}>
            <button onClick={() => setCurrentStep("payment_details")}>
              {false ? (
                <ReactLoading type="spin" width={30} height={30} color="#fff" />
              ) : (
                `Confirm`
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
