import { Http } from "../utils/http";

export const getGiftCode = (payload) => Http.post("/giftcodes", payload);

export const getVirtualAccount = (payload) =>
  Http.post("/giftcodes/virtual-account", payload);

export const madePayment = (reference) =>
  Http.get(`/giftcodes/virtual-account/${reference}/status`);

export const getTransaction = (reference, email) =>
  Http.get(`/giftcodes/status/${reference}/${email}`);

export const getPurchasedGiftCode = (reference, email) =>
  Http.get(`/giftcodes/status/${reference}/${email}`);

export const validateLink = (id) => Http.get(`transaction/${id}`);

export const generateCryptoDetails = (id, payload) =>
  Http.get(`transaction/${id}/crypto/generate-link`);

export const generateLink = (id, payload) =>
  Http.post(`transaction/${id}/crypto/generate-link`, payload);

export const getLink = (id, reference) =>
  Http.get(`transaction/${id}/crypto/get-link?reference=${reference}`);

export const createTransactionFromLink = (id, payload) =>
  Http.post(`transaction/${id}/crypto/create-payment`, payload);

export const cardPayment = (id, payload) =>
  Http.post(`transaction/${id}/charge-card`, payload);
