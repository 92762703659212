import React from "react";
import styles from "./Navbar.module.scss";

//images
import Logo from '../../assets/images/logo.png'

const Navbar = () => {
  return <div className={styles.navbar}>
    { window.location.hostname.includes("dev.") ? <div style={{ position: "absolute", backgroundColor: "red", color: "white", width: "100%", top: 0, left: 0, textAlign: "center" }}>This is a development version</div> : null }
    <img className={styles.navbar__logo} src={Logo} alt="knit logo" />
    {/* <h2 className={styles.navbar__text}>Kiftcode</h2> */}
  </div>;
};

export default Navbar;
