import axios from "axios";

import cookie from "./cookie";

const env =
  window.location.hostname.includes("send.dev") ||
  window.location?.hostname?.includes("localhost")
    ? "dev"
    : "prod";
const REACT_APP_API_BASEURL = `https://api-${env}.blaaiz.com/api`;

export const Http = axios.create({
  baseURL: REACT_APP_API_BASEURL,
  timeout: 45000,
  headers: {
    // "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Http.interceptors.request.use((config) => {
  const token = localStorage.getItem("knit-token");
  const userType = cookie().getCookie("userType");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (userType) {
    config.headers.common["X-User-Type"] = userType;
  }

  return config;
});

Http.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status) {
      if (
        error.response.status === 401 ||
        error?.response?.data?.statusCode == 401
      ) {
        // console.log("removed token")
        localStorage.removeItem("knit-token");
      }

      if (error.response.status === 500) {
        console.log("Server error occurred");
      }
    }

    return Promise.reject(error);
  }
);

export default Http;
