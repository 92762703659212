import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import Success from "../Success/Success";
import Failed from "../Failed/Failed";

import styles from "./Transfer.module.scss";
import { WarningCircle } from "phosphor-react";

const Transfer = ({
  setCurrentStep,
  _virtualAccount,
  setTransferDetails,
  isLoading,
  amount,
}) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     setCurrentStep("transfer_confirmation")
  //   }, 3000);
  // }, [])

  const [transferFormData, setTransferFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const handleTransferForm = (transferFormData) => {
    const emptyItem = Object.keys(transferFormData).find(
      (key) => !transferFormData[key]
    );

    if (emptyItem) {
      toast.error(`${emptyItem?.replaceAll("_", " ")} is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    setTransferDetails(transferFormData);

    _virtualAccount({
      ...transferFormData,
      amount: Number(amount) / 100,
    });
    // _getGiftCode({
    //   //   ...transferFormData,
    //   email: transferFormData.email,
    //   account_name: transferFormData.phone_number,
    //   amount: Number(amount) / 100,
    //   card_number: transferFormData?.card_number?.replaceAll(" ", ""),
    //   cvc: transferFormData?.cvc,
    //   expiry: transferFormData?.expiry?.replaceAll(" ", ""),
    // });
  };
  return (
    <>
      <div className={styles.transfer}>
        <h2 className={styles.transfer__heading}>Bank Transfer</h2>

        <div className={styles.transfer__form}>
          {/* <div className={styles.transfer__form__account}>
              <div className={styles.transfer__form__account__details}>
                <p>0017663214</p>
                <p>Providus Bank</p>
                <p>Payaza - Omokhoa</p>
              </div>
            </div> */}

          <div className={styles.transfer__form__item}>
            <label htmlFor="first_name">First Name</label>
            <input
              className={styles.transfer__form__item__input}
              type="text"
              name="first_name"
              id="first_name"
              value={transferFormData?.first_name}
              onChange={(e) =>
                setTransferFormData({
                  ...transferFormData,
                  first_name: e.target.value,
                })
              }
            />
          </div>

          <div className={styles.transfer__form__item}>
            <label htmlFor="last_name">Last Name</label>
            <input
              className={styles.transfer__form__item__input}
              type="text"
              name="last_name"
              id="last_name"
              value={transferFormData?.last_name}
              onChange={(e) =>
                setTransferFormData({
                  ...transferFormData,
                  last_name: e.target.value,
                })
              }
            />
          </div>
          <div className={styles.transfer__form__item}>
            <label htmlFor="phone_number">Phone number</label>
            <input
              className={styles.transfer__form__item__input}
              type="text"
              name="phone_number"
              id="phone_number"
              value={transferFormData?.phone_number}
              onChange={(e) =>
                setTransferFormData({
                  ...transferFormData,
                  phone_number: e.target.value,
                })
              }
            />
          </div>

          <div className={styles.transfer__form__item}>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              className={styles.transfer__form__item__input}
              name="email"
              id="email"
              value={transferFormData?.email}
              onChange={(e) =>
                setTransferFormData({
                  ...transferFormData,
                  email: e.target.value,
                })
              }
            />
          </div>

          <p className={styles.transfer__form__notice}>
            <WarningCircle size={18} color="rgba(255, 165, 0, 1)" />
            Purchased Blaizs can only be redeemed by the purchaser
          </p>

          <div className={styles.transfer__form__item}>
            <button onClick={() => handleTransferForm(transferFormData)}>
              {isLoading ? (
                <ReactLoading type="spin" width={30} height={30} color="#fff" />
              ) : (
                `Next`
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transfer;
