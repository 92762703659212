import React, { useEffect, useRef } from "react";
import { ArrowCircleLeft, XCircle, CaretLeft } from "phosphor-react";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import styles from "./ModalWrapper.module.scss";

const ModalWrapper = ({
  children,
  userHistory,
  setUserHistory,
  setCurrentStep,
  currentStep,
  showModal,
  setShowModal,
}) => {
  const selectRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(selectRef);

  //   useEffect(() => {
  //     if (isOutOfFocus) {
  //       setShowModal(false);
  //     }
  //   }, [isOutOfFocus]);

  useEffect(() => {}, [userHistory]);

  const handleBack = () => {
    let _userHistory = userHistory?.length > 0 ? userHistory : [];
    _userHistory.pop();
    console.log(_userHistory);
    setCurrentStep(_userHistory[_userHistory.length - 1]);
    setUserHistory(_userHistory);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__box}>
        <div ref={selectRef} className={styles.wrapper__main}>
          {currentStep === "payment_options" ||
          currentStep === "invalid" ||
          currentStep === "crypto_option" ||
          currentStep === "success" ||
          currentStep === "failed" ||
          currentStep === "verify" ||
          currentStep === "payment_details" ||
          currentStep === "confirmation" ? null : (
            <div
              onClick={() => handleBack()}
              className={styles.wrapper__cancel}
            >
              <CaretLeft size={15} color="#556BF6" weight="fill" /> <p>Back</p>
            </div>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
