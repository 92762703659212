import React from "react";
import { Copy } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import styles from "./Invalid.module.scss";

//images
import InvalidIcon from "../../assets/icons/failed.gif";

const Invalid = ({ setTransaction }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.invalid}>
      <img src={InvalidIcon} className={styles.invalid__icon} />
      <h3>Link invalid</h3>
      <p className={styles.invalid__message}>
        We weren’t able to find the transaction associated with this link.
        Please check try again.
      </p>

      <div className={styles.invalid__buttons}>
        {/* <button
         
        >
          Go home
        </button> */}
      </div>
    </div>
  );
};

export default Invalid;
