import userEvent from "@testing-library/user-event";
import React, { useState, useRef, useEffect } from "react";
import styles from "./InputPin.module.scss";

const InputPin = ({ value, setValue, ...props }) => {
  const [PinOne, setPinOne] = useState();
  const [PinTwo, setPinTwo] = useState();
  const [PinThree, setPinThree] = useState();
  const [PinFour, setPinFour] = useState();
  const [PinFive, setPinFive] = useState();
  const [PinSix, setPinSix] = useState();

  const PinOneRef = useRef(null);
  const PinTwoRef = useRef(null);
  const PinThreeRef = useRef(null);
  const PinFourRef = useRef(null);
  const PinFiveRef = useRef(null);
  const PinSixRef = useRef(null);

  const refs = [
    PinOneRef,
    PinTwoRef,
    PinThreeRef,
    PinFourRef,
    PinFiveRef,
    PinSixRef,
  ];

  const style = {};

  const handleChange = (e, index, setStateFunction) => {
    if (e.keyCode == 8 || e?.keyCode == 46) {
      setStateFunction("");
      refs[index === 0 ? index : index - 1].current?.focus();
      return;
    }
    const value = e?.key;
    console.log(value, e, e?.value, "value");
    if (isNaN(value) || isNaN(parseFloat(value))) {
      setStateFunction("");
      return;
    }
    if (index < 6 && value.length > 0) {
      setStateFunction(value);
      refs[index === 5 ? index : index + 1].current?.focus();
    }
  };

  // useEffect(() => {
  //   setPinOne(value[0] ?? "");
  //   setPinTwo(value[1] ?? "");
  //   setPinThree(value[2] ?? "");
  //   setPinFour(value[3] ?? "");
  //   setPinFive(value[4] ?? "");
  //   setPinSix(value[5] ?? "");
  // }, [value]);

  useEffect(() => {
    const _value = `${PinOne ?? ""} ${PinTwo ?? ""} ${PinThree ?? ""} ${
      PinFour ?? ""
    } ${PinFive ?? ""} ${PinSix ?? ""}`;

    setValue(_value);
  }, [PinOne, PinTwo, PinThree, PinFour, PinFive, PinSix]);

  return (
    <div className={styles?.inputPin}>
      <input
        {...props}
        ref={PinOneRef}
        value={PinOne}
        type={props?.type ?? "tel"}
        maxLength={1}
        style={style}
        onKeyDown={(e) => handleChange(e, 0, setPinOne)}
      />
      <input
        {...props}
        ref={PinTwoRef}
        value={PinTwo}
        type={props?.type ?? "tel"}
        maxLength={1}
        onKeyDown={(e) => handleChange(e, 1, setPinTwo)}
      />
      <input
        {...props}
        ref={PinThreeRef}
        value={PinThree}
        onKeyDown={(e) => handleChange(e, 2, setPinThree)}
        type={props?.type ?? "tel"}
        maxLength={1}
      />
      <input
        {...props}
        ref={PinFourRef}
        value={PinFour}
        type={props?.type ?? "tel"}
        maxLength={1}
        onKeyDown={(e) => handleChange(e, 3, setPinFour)}
      />
      <input
        {...props}
        ref={PinFiveRef}
        value={PinFive}
        type={props?.type ?? "tel"}
        maxLength={1}
        onKeyDown={(e) => handleChange(e, 4, setPinFive)}
      />
      <input
        {...props}
        ref={PinSixRef}
        value={PinSix}
        type={props?.type ?? "tel"}
        maxLength={1}
        onKeyDown={(e) => handleChange(e, 5, setPinSix)}
      />
    </div>
  );
};

export default InputPin;
