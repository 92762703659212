import React from "react";
import { Copy } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import styles from "./ComingSoon.module.scss";

//images
import ComingSoonImage from "../../assets/images/coming-soon.png";

const ComingSoon = ({  }) => {
  return (
    <div className={styles.comingSoon}>
      <img src={ComingSoonImage} className={styles.comingSoon__icon} />
      <p className={styles.comingSoon__message}>Bank transfer option coming soon</p>

      <div className={styles.comingSoon__buttons}>
        {/* <button
         
        >
          Go home
        </button> */}
      </div>
    </div>
  );
};

export default ComingSoon;
