import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import {
  Copy,
  CurrencyDollar,
  CurrencyNgn,
  WarningCircle,
} from "phosphor-react";
import {
  // getCoinValueForLocalCurrency,
  createTransactionFromLink,
  //  getTransaction,
} from "../../services";
import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

import EthereumLogo from "../../assets/icons/ethereum.png";
import BitcoinLogo from "../../assets/icons/bitcoin.png";
import PolygonLogo from "../../assets/icons/polygon.png";
import BSCNETWORKLogo from "../../assets/icons/bsc-network.jpg";
import BNBLogo from "../../assets/icons/bnb.jpg";
import USDT_BSC from "../../assets/icons/usdt-bsc.jpg";
import USDT_OTHER from "../../assets/icons/usdt-other.jpg";
import Dai from "../../assets/icons/dai.jpg";
import Busd from "../../assets/icons/busd.png";
import Usdc from "../../assets/icons/usdc.png";

//components
import Select from "../Select/Select";
import Success from "../Success/Success";
import Failed from "../Failed/Failed";

import styles from "./PaymentCrypto.module.scss";

const PaymentCrypto = ({
  _coinOptions,
  transactionData,
  userData,
  _generateLink,
  isGettingLink,
  isGeneratingLink,
  transactionId,
}) => {
  const [status, setStatus] = useState("initial");
  const [transactionStatus, setTransactionStatus] = useState("PENDING");
  const [shouldCheckStatus, setShouldCheckStatus] = useState(false);
  const [activeNetwork, setActiveNetwork] = useState();
  const [coinOptions, setCoinOptions] = useState();
  const [networks, setNetworks] = useState();
  const currencyValue = {};

  useEffect(() => {
    _generateLink();
  }, []);

  useEffect(() => {
    if (coinOptions) {
      const _networks = [];
      for (let p in coinOptions) {
        if (p === "BTC") {
          _networks.push({
            name: "Bitcoin",
            short: "BTC",
            key: "BTC",
            icon: BitcoinLogo,
          });
        } else if (p === "ETHEREUM_MAINNET") {
          _networks.push({
            name: "Ethereum",
            key: "ETHEREUM_MAINNET",
            short: "ETH",
            icon: EthereumLogo,
          });
        } else if (p === "MATIC_MAINNET") {
          _networks.push({
            name: "Polygon",
            key: "MATIC_MAINNET",
            short: "MATIC",
            icon: PolygonLogo,
          });
        } else if (p === "BSC_MAINNET") {
          _networks.push({
            name: "BSC",
            key: "BSC_MAINNET",
            short: "BSC",
            icon: BSCNETWORKLogo,
          });
        } else {
          _networks.push({
            name: p,
            key: p,
            short: p,
            icon: BitcoinLogo,
          });
        }
      }
      setNetworks(_networks);
      setActiveNetwork(_networks[0]);
    }
  }, [coinOptions]);

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);

    toast.success("Address copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const [coins, setCoins] = useState([]);
  const [activeCoin, setActiveCoin] = useState({});

  useEffect(() => {
    if (_coinOptions) {
      setCoinOptions(_coinOptions);
    }
  }, [_coinOptions]);

  //GET CURRENT RATE
  // const { data: currencyValue } = useQuery(
  //   ["get latest btc value to naira", activeCoin],
  //   () =>
  //     getCoinValueForLocalCurrency(
  //       "NGN",
  //       transactionData?.local_amount,
  //       activeCoin?.name ?? "BTC",
  //       transactionData?.user_id
  //     ),
  //   {
  //     onSuccess: (data) => {},
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   }
  // );

  useEffect(() => {
    if (_coinOptions) {
      setCoinOptions(_coinOptions);
    }
  }, [_coinOptions]);

  // CREATE TRANSACTION
  const {
    mutate: _createTransactionFromLink,
    data: _transactionDataFromLink,
    isLoading: _isCreatingTransaction,
  } = useMutation(
    ["create transaction from link", activeCoin],
    (payload) => createTransactionFromLink(transactionId, payload),
    {
      onSuccess: (data) => {
        console.log(data, "transaction link data");
      },
      onError: (error) => {
        console.log(error);
        toast.error(error?.response?.data?.message || "An error occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      },
    }
  );

  //createTransactionInvoice
  // const { isFetching: isGettingStatus } = useQuery(
  //   ["get transaction status", _transactionDataFromLink],
  //   () =>
  //     getTransaction(transactionData?.reference, _transactionDataFromLink?.id),
  //   {
  //     refetchInterval:
  //       transactionStatus === "SUCCESSFUL" || transactionStatus === "FAILED"
  //         ? 0
  //         : 5000,
  //     enabled: shouldCheckStatus,
  //     onSuccess: (data) => {
  //       console.log(data, "transaction data");
  //       setTransactionStatus(data?.status);
  //     },
  //     onError: (error) => {
  //       console.log({ error });
  //       toast.error(error?.response?.data?.message ?? "An error occurred", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     },
  //   }
  // );

  useEffect(() => {
    if (coinOptions && activeNetwork) {
      const _coins = [];
      coinOptions[activeNetwork?.key].tokens?.forEach((item) => {
        if (item === "BTC") {
          //PaymentOption
          _coins.push({ name: "BTC", short: "BTC", icon: BitcoinLogo });
        } else if (item === "ETH") {
          _coins.push({
            name: "ETH",
            short: "ETH",
            icon: EthereumLogo,
          });
        } else if (item === "BUSD") {
          _coins.push({
            name: "BUSD",
            short: "BUSD",
            icon: Busd,
          });
        } else if (item === "USDC") {
          _coins.push({
            name: "USDC",
            short: "USDC",
            icon: Usdc,
          });
        } else if (item === "BNB") {
          _coins.push({
            name: "BNB",
            short: "BNB",
            icon: BNBLogo,
          });
        } else if (item === "DAI") {
          _coins.push({
            name: "DAI",
            short: "DAI",
            icon: Dai,
          });
        } else if (item === "MATIC") {
          _coins.push({
            name: "MATIC",
            short: "MATIC",
            icon: PolygonLogo,
          });
        } else if (item === "USDT" && activeNetwork?.key === "BSC_MAINNET") {
          _coins.push({
            name: "USDT",
            short: "USDT_BSC_MAINNET",
            icon: USDT_BSC,
          });
        } else if (item === "USDT" && activeNetwork?.key !== "BSC_MAINNET") {
          _coins.push({
            name: "USDT",
            short: "USDT",
            icon: USDT_OTHER,
          });
        } else {
          _coins.push({
            name: item,
            short: item,
            icon: EthereumLogo,
          });
        }
      });
      setCoins(_coins);
      setActiveCoin(_coins[0]);
    }
  }, [activeNetwork, coinOptions]);

  return (
    <>
      {isGettingLink || isGeneratingLink || true ? (
        <div className={styles.crypto__loading}>
          <div className={styles.crypto__loading__content}>
            {/* <p>Please wait</p> */}
            <ReactLoading type="spinningBubbles" color="#222" width={30} height={30} />
          </div>
        </div>
      ) : null}

      {transactionStatus === "PENDING" &&
      !isGeneratingLink &&
      !isGettingLink &&
      false ? (
        <div className={styles.crypto}>
          <h2 className={styles.crypto__heading}>Payment via Crypto</h2>
          <p className={styles.crypto__subHeading}>{`Invoice from  ${
            userData?.name ?? "- -"
          }`}</p>

          <div className={styles.crypto__main}>
            {!_transactionDataFromLink?.toAddress ? (
              <div className={styles.crypto__main__left}>
                <div className={styles.crypto__main__amount}>
                  <h2 className={styles.crypto__main__amount__naira}>
                    <CurrencyDollar weight="bold" color="#5C5C5C" size={32} />
                    <span>
                      {(transactionData?.local_amount ?? 0).toLocaleString()}
                    </span>
                  </h2>

                  {/* <div className={styles.crypto__main__amount__crypto}>
                    <p>
                      {currencyValue?.number_of_coins} {currencyValue?.coin}
                    </p>

                    <img
                      className={styles.crypto__main__amount__crypto__img}
                      alt="coin logo"
                      src={activeCoin?.icon ?? ""}
                    />
                  </div> */}
                </div>

                <div className={styles.crypto__main__select}>
                  <Select
                    active={activeNetwork}
                    setActive={setActiveNetwork}
                    options={networks}
                    heading="Network"
                  />
                  <Select
                    heading="Coin"
                    active={activeCoin}
                    setActive={setActiveCoin}
                    options={coins ? coins : []}
                  />
                </div>

                <button
                  onClick={() => {
                    _createTransactionFromLink({
                      coin: activeCoin?.name,
                      network: activeNetwork?.key,
                      reference: transactionData?.reference,
                      user_id: transactionData?.user_id?.toString(),
                    });
                  }}
                  className={styles.crypto__main__button}
                >
                  {_isCreatingTransaction ? (
                    <ReactLoading
                      type="spin"
                      width={30}
                      height={30}
                      color="#fff"
                    />
                  ) : (
                    `Confirm`
                  )}
                </button>
              </div>
            ) : (
              <div className={styles.crypto__main__right}>
                <div className={styles.crypto__main__amount}>
                  <h2 className={styles.crypto__main__amount__naira}>
                    <CurrencyNgn weight="bold" color="#5C5C5C" size={32} />
                    <span>
                      {(transactionData?.local_amount ?? 0).toLocaleString()}
                    </span>
                  </h2>

                  <div className={styles.crypto__main__amount__crypto}>
                    <p>
                      {_transactionDataFromLink?.coin_amount}{" "}
                      {_transactionDataFromLink?.coin}
                    </p>

                    <img
                      className={styles.crypto__main__amount__crypto__img}
                      alt="coin logo"
                      src={activeCoin?.icon ?? ""}
                    />
                  </div>
                  <p className={styles.crypto__main__amount__rate}>
                    1{_transactionDataFromLink?.coin} ~{" "}
                    {(
                      _transactionDataFromLink?.exchange_rate /
                      _transactionDataFromLink?.coin_to_usd
                    ).toFixed(2)}
                    {_transactionDataFromLink?.local_currency}
                  </p>
                  <div className={styles.crypto__main__qrCode}>
                    <QRCodeSVG
                      level="H"
                      size={220}
                      background="#EFEFEF"
                      value={_transactionDataFromLink?.toAddress ?? "#"}
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleCopy(_transactionDataFromLink?.toAddress)
                    }
                    className={styles.crypto__main__link}
                  >
                    <span>{`${_transactionDataFromLink?.toAddress.slice(
                      0,
                      9
                    )}...${_transactionDataFromLink?.toAddress.slice(
                      -5
                    )}`}</span>
                    <Copy size={25} color="#000" />
                  </div>
                  <div className={styles.crypto__main__warning}>
                    <div>
                      <WarningCircle
                        color="#0F295C"
                        size={20}
                        weight="regular"
                      />
                    </div>
                    <p>
                      To avoid losing your fund, please ensure you're sending
                      from a{" "}
                      <b>
                        {_transactionDataFromLink?.network?.replace("_", " ")} (
                        {coinOptions[activeNetwork?.key]?.alias})
                      </b>
                    </p>
                  </div>
                </div>
                <button
                  onClick={() =>
                    window?.location?.replace(
                      transactionData?.merchant_client_redirect_url
                    )
                  }
                  className={styles.crypto__main__button}
                >
                  {
                    // isGettingStatus || shouldCheckStatus
                    false ? (
                      <ReactLoading
                        type="spin"
                        width={30}
                        height={30}
                        color="#fff"
                      />
                    ) : (
                      `I have made payment`
                    )
                  }
                </button>
              </div>
            )}
          </div>

          {/* <button >
              </button> */}
        </div>
      ) : null}
      {transactionStatus === "SUCCESSFUL" && (
        <Success setStatus={setStatus} channel={transactionData?.channel} />
      )}
      {transactionStatus === "FAILED" && (
        <Failed setStatus={setStatus} channel={transactionData?.channel} />
      )}
    </>
  );
};

export default PaymentCrypto;
