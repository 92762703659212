import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";

import styles from "./Verify.module.scss";
import { WarningCircle } from "phosphor-react";
import Select from "../Select/Select";
import SelectField from "../SelectMain/Select";

const Verify = ({ setCurrentStep }) => {
  const [verifyFormData, setVerifyFormData] = useState({
    bank: "",
    account_name: "",
    account_number: "",
    phone_number: "",
  });

  const banks = [
    { label: "First Bank", value: "" },
    { label: "Second Bank", value: "" },
    { label: "Third Bank", value: "" },
  ];

  const handleVerifyForm = (verifyFormData) => {
    const emptyItem = Object.keys(verifyFormData).find(
      (key) => !verifyFormData[key]
    );

    if (emptyItem) {
      toast.error(`${emptyItem?.replaceAll("_", " ")} is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // setVerifyDetails(verifyFormData);

    // _virtualAccount({
    //   ...verifyFormData,
    //   amount: Number(amount) / 100,
    // });
  };
  return (
    <>
      <div className={styles.verify}>
        <h2 className={styles.verify__heading}>Verify Account</h2>
        <p className={styles.verify__desc}>
          Hi there, we noticed this is your first time using Blaiz, kindly
          register your account
        </p>
        <div className={styles.verify__form}>
          <div className={styles.verify__form__item}>
            <label htmlFor="phone_number">Phone number</label>
            <input
              className={styles.verify__form__item__input}
              type="text"
              name="phone_number"
              id="phone_number"
              value={verifyFormData?.phone_number}
              onChange={(e) =>
                setVerifyFormData({
                  ...verifyFormData,
                  phone_number: e.target.value,
                })
              }
            />
          </div>

          <div className={styles.verify__form__item}>
            <label htmlFor="bank">Bank</label>
            <SelectField
              // className={styles.verify__form__item__input}
              type="text"
              name="bank"
              id="bank"
              setVerifyFormData={setVerifyFormData}
              verifyFormData={verifyFormData}
              options={banks}
            />
          </div>

          <div className={styles.verify__form__item}>
            <label htmlFor="account_name">Account Name</label>
            <input
              className={styles.verify__form__item__input}
              type="text"
              name="account_name"
              id="account_name"
              value={verifyFormData?.account_name}
              onChange={(e) =>
                setVerifyFormData({
                  ...verifyFormData,
                  account_name: e.target.value,
                })
              }
            />
          </div>

          <div className={styles.verify__form__item}>
            <label htmlFor="account_number">Account Number</label>
            <input
              type="account_number"
              className={styles.verify__form__item__input}
              name="account_number"
              id="account_number"
              value={verifyFormData?.account_number}
              onChange={(e) =>
                setVerifyFormData({
                  ...verifyFormData,
                  account_number: e.target.value,
                })
              }
            />
          </div>

          {/* <p className={styles.verify__form__notice}>
            <WarningCircle size={18} color="rgba(255, 165, 0, 1)" />
            Purchased Blaizs can only be redeemed by the purchaser
          </p> */}

          <div className={styles.verify__form__item}>
            <button onClick={() => setCurrentStep("confirmation")}>
              {false ? (
                <ReactLoading type="spin" width={30} height={30} color="#fff" />
              ) : (
                `Next`
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
