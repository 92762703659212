import { cloneElement, useState } from "react";

const Layout = ({ children }) => {
  const [formData, setFormData] = useState({
    card_holder_name: "",
    cvc: "",
    expiry: "",
    card_number: "",
    // card_holder_email: "",
  });
  return (
    <div>
      {" "}
      {cloneElement(children, {
        formData,
        setFormData,
      })}
    </div>
  );
};

export default Layout;
