import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

// pages
import LandingPage from "../pages/Home/Home";
import Redirect from "../pages/Redirect/Redirect";
import Track from "../pages/Track/Track";
import Layout from "../layout/Layout";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route
          path="/"
          element={
            <Layout>
              <LandingPage />
            </Layout>
          }
        />
        <Route
          path="/:transactionId"
          element={
            <Layout>
              <LandingPage />
            </Layout>
          }
        />
        <Route
          path="/track"
          element={
            <Layout>
              <Track />
            </Layout>
          }
        />
        <Route
          path="/track/:transactionId"
          element={
            <Layout>
              <Track />
            </Layout>
          }
        />
        <Route
          path="/buy/transaction/:reference/:email"
          element={
            <Layout>
              <Redirect />
            </Layout>
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
