import React, { useRef, useState, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutOfFocus(ref) {
  const [isOutOfFocus, setIsOutOfFocus] = useState(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        setIsOutOfFocus(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return { isOutOfFocus , setIsOutOfFocus };
}

export default useOutOfFocus;
