import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Select from "react-select";

import styles from "./Select.module.scss";

const SelectField = ({ verifyFormData, setVerifyFormData, ...props }) => {
  // const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#79879A;",
      };
    },
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "#f4f4f4",
      width: "100%",
      height: "56px",
      border: state.isFocused
        ? "1px solid #d1d1d1 !important"
        : "1px solid #d1d1d1",
      outline: "none !important",
      borderRadius: "5px",
      fontFamily: "Poppins",
      fontWeight: "normal",
      fontSize: ".8rem",
      color: "#79879a",
      boxShadow: "none",
      ...props.CustomStyles,
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      fontSize: ".8rem",
      fontFamily: "Inter",
      fontWeight: "normal",
      cursor: "red",
      color: "#444",
      backgroundColor: isSelected ? "inherit" : "inherit",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px ",
    }),
  };
  //#95a1b0

  const onChange = ({ value }) => {
    setVerifyFormData({
      ...verifyFormData,
      bank: value,
    });
    setSelectedOption(
      props?.options?.find((option) => option?.value === value)
    );
  };

  return (
    <Select
      {...props}
      value={selectedOption}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      onChange={onChange}
    />
  );
};

export default SelectField;
